<template>
  <multiselect
    ref="multiselect"
    :multiple="multiple"
    v-model="field.selected"
    :value="field.value"
    @blur.native="$emit('blur')"
    @open="onOpen"
    @close="onClose"
    @select="onSelect"
    @input="onInput"
    @search-change="onSearchChange"
    :internal-search="internalSearch"
    :deselect-label="field.deselectLabel || ''"
    track-by="value"
    label="text"
    :preserveSearch="true"
    :placeholder="field.placeholder || ''"
    :options="options"
    :searchable="field.searchable || false"
    :allowEmpty="field.allowEmpty || false"
    :disabled="field.disabled || false"
    :select-label="field.selectLabel || ''"
    :selected-label="field.selectedLabel || ''"
    :loading="loading"
  >

    <template slot="option" slot-scope="{ option }">
      <div>{{ option.text }}</div>
    </template>

    <template slot="noResult">
      <div>No results found</div>
    </template>

    <template slot="noOptions">
      <div v-if="loading">Loading...</div>
      <div v-else></div>
    </template>

    <template slot="afterList">
      <infinite-loading ref="InfiniteLoading" @infinite="loadMore" spinner="waveDots">
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </template>

  </multiselect>

</template>

<script>

import Multiselect from '@/components/vue-multiselect/Multiselect'

import { api } from '@/api.js'

import { debounce } from 'lodash'

export default {

  name: 'SelectField',

  components: {
    Multiselect
  },

  props: {
    field: { type: Object },
    multiple: { type: Boolean, default: false }
  },

  data() {
    return {
      loading: false,
      loaded: false,
      opened: false,
      search: '',
      page: 1,
      pageLimit: 50,
      pageOffset: 0,
      hasMore: true,
      stateChanger: null,
      internalSearch: false,
      options: []
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.stateChanger = this.$refs.InfiniteLoading.stateChanger;
    });
  },

  methods: {

    onOpen() {
      this.opened = true;
      if (!this.loaded && !this.loading && !this.options.length) {
        this.loadPage(1);
      }
    },

    onClose() {
      this.opened = false;
    },

    scrollToTopOfList() {
      this.$refs.multiselect.$refs.list.scrollTo(0,0);
    },

    onSearchChange(search) {
      this.search = search;
      this.stateChanger.reset();
      this.page = 1;
      this.scrollToTopOfList();
      this.loadPage({ search });
    },

    onInput(value) {
      value ? this.field.value = value.value : false;
    },

    onSelect() {
      this.search = '';
    },

    loadMore() {
      let page = this.page + 1;
      let more = true;
      this.loadPage({ page, more });
    },

    setPage(page = 1) {
      this.page = page;
      this.pageOffset = (page * this.pageLimit) - this.pageLimit;
    },

    loadPage(opts) {

      let search = opts['search'] || this.search || '';
      let page   = opts['page']   || this.page || 1;
      let more   = opts['more']   || false;

      this.search = search;

      if (this.loading) { return; }

      this.loading = true;

      this.setPage(page);

      this.fetchData().then( ({ data }) => {

        let options = data.map( o => ({
          "text" : o.factorvalue,
          "value": o.factorvalueid
        }));

        more ? this.options.push(...options)
             : this.options = options;
      });

    },

    fetchData() {

      return new Promise(resolve => {

        debounce(() => {

          let factornameid = this.field['factornameid'] || 0;
          let search = this.search;
          let pageLimit = this.pageLimit;
          let pageOffset = this.pageOffset;

          this.loading = true;

          return api.get(`/factor?filter[factornameid]=${factornameid}&filter[factorvalue]=~${search}&fields=factorvalue,factorvalueid&page[limit]=${pageLimit}&page[offset]=${pageOffset}`)

            .then((resp) => {
              let raw = resp.data || {};
              let data = raw.data || [];
              let meta = raw.meta || {};

              this.loading = false;
              this.loaded  = true;

              this.hasMore = meta.hasMore || false;
              this.hasMore ? this.stateChanger.loaded()
                           : this.stateChanger.complete();

              return resolve({ data, meta });
            })

            .catch((error) => {
              this.loading = false;
              this.loaded  = true;

              return resolve({ data: [], meta: {}, error });
            });

        }, 1000)();
      });
    }

  }

}
</script>
